import * as olSource from "ol/source";

function bing(imagerySet, locale, logo) {
	return new olSource.BingMaps({
		key: 'Al-BD1MIHcUkOz_FFn_J1hoFpdnZ-5twG8aDO4l8YbKL3PGI9Ai0qzOIYQE7JDcX',
		imagerySet: imagerySet,
		culture: locale === 'el' ? 'EL-el' : 'En-en'
	});
}

export default bing;