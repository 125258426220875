import React from "react";
import { connect } from 'react-redux';
import { Card, CardBody, CardTitle } from 'reactstrap';

import Akz from "../modals/zones";
import Temperature from "../modals/temperature";
import "../../css/style.css";


const Zones = (props) => {

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h5">{props.i18n.messages['evaluation']}</CardTitle>
        <CardBody>
          {props.i18n.messages['zonesText']}
        </CardBody>
        <Akz />
        {
        //<Temperature />
        }
      </CardBody>
    </Card>
  );
};


const mapStateToProps = (state) => ({
  loggedin: state.profile.loggedin,
  i18n: state.i18n,
  profile: state.profile,
  notifications: state.notifications.messages,
  modalOpen: state.ui.modal.modalOpen,
  list: state.list,
});

export default connect(mapStateToProps)(Zones);